import React from "react";
import PropTypes from "prop-types";

export default function ContactRow({ blok }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-6">
      <div className="col-span-1 flex justify-center">
        <img className="w-3/5 lg:w-full" src={blok.image.filename}></img>
      </div>

      <div className="col-span-3 flex items-center text-center lg:text-left justify-center lg:justify-start">
        {blok.link != "" && (
          <a
            href={blok.link}
            target="_blank"
            rel="noreferrer"
            title={blok.title}
          >
            <p className="text-white text-2xl lg:text-5xl title-font font-regular text-white px-4">
              {blok.title}
            </p>
          </a>
        )}
        {blok.link == "" && (
          <p className="text-white text-2xl lg:text-5xl title-font font-regular text-white px-4">
            {blok.title}
          </p>
        )}
      </div>
    </div>
  );
}

ContactRow.propTypes = {
  blok: PropTypes.object,
};
