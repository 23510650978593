import React from "react";
import RichText from "~components/richText";
import PropTypes from "prop-types";
import FluidImage from "~components/fluidImage";

function ImageWithTextRow({ blok }) {
  var reverse = blok.reverse;
  return (
    <div className="lg:grid lg:grid-cols-5 lg:gap-16 py-8 lg:py-16">
      <div
        className={`mt-auto mb-auto col-span-2 ${
          reverse ? "order-2" : "order-1"
        }`}
      >
        <div className="object-cover hero" style={{ height: "25rem" }}>
          <FluidImage image={blok.image} blok={blok} />
        </div>
      </div>
      <div
        className={`col-span-3 mt-auto mb-auto ${
          !reverse ? "order-2" : "order-1"
        } text-center lg:text-left`}
      >
        <h4
          className={`text-xl lg:text-3xl py-2 pt-4 lg:py-2 font-regular tracking-tight text-theme-grey`}
        >
          {blok.subline}
        </h4>

        <h3
          className={`text-2xl lg:text-4xl font-bold tracking-tight text-white uppercase`}
        >
          {blok.headline}
        </h3>

        <p
          className={`font-regular text-xl py-4 lg:py-2 text-white leading-10`}
        >
          <RichText document={blok.copy} />
        </p>
      </div>
    </div>
  );
}

ImageWithTextRow.propTypes = {
  blok: PropTypes.object,
};

export default ImageWithTextRow;
