import React, {useEffect} from "react";
import PropTypes from "prop-types";
import RichText from "~components/richText";
import { Link } from "gatsby";
import FluidImage from "~components/fluidImage";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function ImageWithTextOverlap({ blok }) {
  var reverse = blok.reverse;

  var bg = blok.background_color;

  var primaryColor = blok.primary_color || "black";

  var secondaryColor = blok.secondary_color || "theme-grey";

  var fixBottom = blok.unpad_bottom;

  gsap.registerPlugin(ScrollTrigger);


  useEffect(() => {
    let proxy = { skew: 0 },
        skewSetter = gsap.quickSetter(".skewElem", "skewY", "deg"), // fast
        clamp = gsap.utils.clamp(-1, 1); // don't let the skew go beyond 20 degrees. 

    ScrollTrigger.create({
        onUpdate: (self) => {

            let skew = clamp(self.getVelocity() / -400);
            // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
            if (Math.abs(skew) > Math.abs(proxy.skew)) {
                proxy.skew = skew;
                gsap.to(proxy, { skew: 0, duration: 0.8, ease: "power3", overwrite: true, onUpdate: () => skewSetter(proxy.skew) });
            }
        }
    });

    // make the right edge "stick" to the scroll bar. force3D: true improves performance
    gsap.set(".skewElem", { transformOrigin: "right center", force3D: true });

}, []);


  return (
    <section>
      <div
        className={`px-4 sm:px-6 lg:px-8 bg-${bg} relative ${
          fixBottom ? "pt-32 xl:pt-48 pb-0" : "py-32 xl:py-48"
        }`}
      >
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="relative">
            <div className="relative md:p-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
                <div
                  className={`text-gray-500 lg:max-w-none bg-${bg} p-2 lg:p-8 text-center lg:text-left order-2 ${
                    !reverse
                      ? "lg:order-2 lg:-ml-64 lg:-mt-12 lg:ml-32"
                      : "lg:order-1 lg:-mr-64 lg:-mt-12 lg:mr-32"
                  } z-20`}
                >
                  <h2
                    className={`text-3xl font-bold tracking-tight text-${primaryColor} uppercase sm:text-5xl leading-10`}
                  >
                    {blok.headline}
                  </h2>

                  <div
                    className={`font-regular text-xl py-6 text-${secondaryColor} leading-10`}
                  >
                    <RichText document={blok.copy} />
                  </div>
                  <div>
                    {blok.cta_text && (
                      <Link
                        to={blok.cta_link}
                        className={`group inline-flex items-center justify-center border border-transparent text-base font-medium text-2xl text-${primaryColor} pb-4`}
                      >
                        {blok.cta_text}
                        <ArrowRightIcon
                          className="ml-3 mb-1 -mr-0.5 h-5 w-5 transition-transform transform group-hover:translate-x-2"
                          aria-hidden="true"
                        />
                      </Link>
                    )}
                  </div>
                </div>

                <div
                  className={`mt-32 lg:mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0 ${
                    reverse ? "lg:order-2" : "lg:order-1"
                  } relative`}
                >

                  <div className="skewElem absolute -mt-96 object-cover height-custom-image relative overlap-image">

                    <FluidImage blok={blok} image={blok.image} />

                  </div>

                </div>
              </div>
              <div className="mt-8 inline-flex"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ImageWithTextOverlap.propTypes = {
  blok: PropTypes.node.isRequired,
};

export default ImageWithTextOverlap;
