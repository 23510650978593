import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";
import PageTransition from 'gatsby-plugin-page-transitions';

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-white">
      <Header />
      <PageTransition>

      <main className="overflow-hidden">{children}</main>
      </PageTransition>

      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
