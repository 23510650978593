import React from "react";

import SbEditable from "storyblok-react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import PropTypes from "prop-types";

const FluidImage = ({ image, blok, blur=true }) => {
  const fluidProps = getFluidGatsbyImage(image.filename, {
    maxWidth: 900,
    quality: "50",
  });

  return (
    <SbEditable content={blok}>
      <Img fluid={fluidProps} placeholderClassName={`${blur ? "placeholder-img": ""}`} fadeIn={blur} draggable={false} alt={image.alt}/>
    </SbEditable>
  );
};

export default FluidImage;

FluidImage.propTypes = {
  image: PropTypes.node.isRequired,
  blok: PropTypes.node.isRequired,
  blur: PropTypes.bool,

};
