import React from "react";

import { render } from "storyblok-rich-text-react-renderer";
import PropTypes from "prop-types";

function RichText({ document }) {
  // document is the rich text object you receive from Storyblok,
  // in the form { type: "doc", content: [ ... ] }
  return <div>{render(document)}</div>;
}

export default RichText;

RichText.propTypes = {
  document: PropTypes.node.isRequired,
};
