/* eslint-disable */

import React, { useState, useRef, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MailIcon } from "@heroicons/react/outline";
import { Link, useStaticQuery, graphql } from "gatsby";
import useStoryblok from "../lib/storyblok";

export default function Footer() {
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  function openModal(event) {
    event.preventDefault();

    setOpen(true);
  }

  const header_data = useStaticQuery(graphql`
  {
    storyblokEntry(full_slug: { eq: "footer" }) {
      content
      name
    }
  }
  `)

  let story = header_data.storyblokEntry;

  story = useStoryblok(story);



  var navigation = {
    main: [

    ],
    social: [

    ],
  };

  for (var block_item in story.content.links) {
    var item = story.content.links[block_item];
    var data_block = { "name": item["headline"], "href": item["link"], "modal": item["open_modal"] }
    navigation.main.push(data_block);
  }

  for (var block_item in story.content.social_links) {
    var item = story.content.social_links[block_item];
    var data_block = { "icon": item.image.filename, "link": item.link, "name": item.name }
    navigation.social.push(data_block);
  }

  useEffect(() => {
    var elements = window.document.getElementsByClassName("open-modal");

    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener(
        "click",
        function (event) {
          openModal(event);
        },
        false
      );
    }
  }, []); // it's important to run the effect only once to avoid multiple event attachment

  return (
    <>
      <form
        method="POST"
        className='hidden'
        action="/thankyou"
        data-netlify="true"
        name="info-pack"
        netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="info-pack" />

        <input
          type="email"
          name="info-pack-email"
          id="info-pack-email"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300"
          placeholder="you@example.com"
        />
        </form>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">

                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-800">
                      <MailIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-white"
                      >
                        Want to learn more?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-white">
                          Input your email below to receive a copy of our
                          information pack.
                        </p>
                      </div>
                    </div>
                    <div>


                      <div className="my-4">
                        <form
                          method="POST"
                          action="/thankyou"
                          data-netlify="true"
                          name="info-pack"
                          netlify-honeypot="bot-field"
                        >
                          <input type="hidden" name="bot-field" />
                          <input type="hidden" name="form-name" value="info-pack" />

                          <input
                            type="email"
                            name="info-pack-email"
                            id="info-pack-email"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300"
                            placeholder="you@example.com"
                          />
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              className="w-full duration-300	inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white transition hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                              onClick={() => setOpen(false)}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="mt-2 duration-300	w-full inline-flex justify-center rounded-md px-4 py-2 text-base font-medium text-white transition hover:bg-red-800 focus:outline-none sm:mt-2 sm:col-start-1 sm:text-sm"
                              onClick={() => setOpen(false)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>

                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <footer className="bg-black border-t-2 border-white">
          <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <nav
              className="-mx-5 -my-2 flex flex-wrap justify-center"
              aria-label="Footer"
            >
              {navigation.main.map((item) => (
                <div
                  key={item.name}
                  className={`px-5 py-2 ${item.modal ? "open-modal" : ""}`}
                >
                  <Link to={item.href} className="text-base transition text-white hover:text-gray-300">
                    {item.name}
                  </Link>
                </div>
              ))}
            </nav>
            <div className="mt-8 flex justify-center space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.link} className={`text-white`} target="_blank">
                  <span className="sr-only">{item.name}</span>
                  <img className="h-6 w-6" aria-hidden="true" src={item.icon} />
                </a>
              ))}
            </div>
            <p className="mt-8 text-center text-base text-white">
              &copy; {new Date().getFullYear()} CTC Electrical Contracting Pty
              Ltd. All rights reserved.
              <br />
              <a href="https://tensq.com.au" className='transition hover:text-gray-300' target="_blank">
                Developed by Ten Squared.
              </a>
            </p>
          </div>
        </footer>
      </>
      );
}
