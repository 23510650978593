import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { ArrowRightIcon } from "@heroicons/react/outline";

function Button({ text = "CONTACT", href = "/contact", arrow = false }) {
  return (
    <Link
      to={href}
      title={text}
      className={`group inline-flex items-center justify-center px-6 pt-2 pb-1 border-2 rounded-md text-lg ${
        arrow ? "border-transparent" : "border-white transition-colors	 hover:bg-theme-grey"
      } text-white bg-transparent`}
    >
      {text}
      {arrow && (
        <ArrowRightIcon
          className="ml-2 mb-1 -mr-0.5 h-5 w-5 transition-transform transform group-hover:translate-x-2"
          aria-hidden="true"
        />
      )}
    </Link>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  arrow: PropTypes.bool,
};

export default Button;
