import React from "react";
import PropTypes from "prop-types";

function ServiceItem({ blok }) {
  var large = blok.large;

  return (
    <div
      className={`p-8 ${
        large ? "w-full md:w-1/2 lg:w-1/3" : "md:w-1/3 lg:w-1/5"
      } flex flex-col text-center items-center`}
      key={`glyphCard${blok.uid}`}
    >
      <div
        className={`${
          large ? "w-40 h-40 lg:w-60 lg:h-60" : "w-36 h-36 lg:w-40 lg:h-40"
        } inline-flex items-center justify-center mb-5 flex-shrink-0 opacity-60 hover-green`}
      >
        <img src={blok.image.filename}></img>
      </div>
      <div className="flex-grow">
        <h2 className="text-white text-xl title-font font-bold mb-3">
          {blok.headline}
        </h2>
      </div>
    </div>
  );
}

export default ServiceItem;

ServiceItem.propTypes = {
  blok: PropTypes.node.isRequired,
};
