import { Link, useStaticQuery , graphql } from "gatsby";
import React, { Fragment, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Button from "~components/button";
import logo from "../images/logos/svg/logo_landscape.svg";
import logoBanner from "../images/logos/svg/ctc_logo.svg";


import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import useStoryblok from "../lib/storyblok";


function Header() {
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);


  const buttonRef = useRef();

  var navigation = [
  ];

  const header_data = useStaticQuery(graphql`
  {
    storyblokEntry(full_slug: { eq: "header" }) {
      content
      name
    }
  }
  `)

  let story = header_data.storyblokEntry;

  story = useStoryblok(story);


  for (var block_item in story.content.block){
    navigation.push({"name": story.content.block[block_item]["headline"],"href": story.content.block[block_item]["link"]})
  }



  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  const handleClickPanel = () => buttonRef.current?.click();




  return (
    <Popover as="header" className="absolute lg:fixed top-0 left-0 z-50 w-full">
      <div
        className={`py-3 transition-colors duration-500 ${showScrolling ? "scrolling" : ""
          } ${showReveal ? "bg-transparent lg:bg-black " : "bg-transparent"}`}
      >
        <nav
          className="relative w-full mx-auto flex items-center justify-between px-4 sm:px-20"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full lg:w-auto">
              <Link to="/">
                <span className="sr-only">CTC Electrical Contracting</span>
                <img
                  className={`h-12 w-auto sm:h-16 transition-all duration-500 hidden ${showReveal
                    ? "lg:hidden lg:opacity-0"
                    : "lg:block lg:opacity-100"
                    }`}
                  src={logo}
                  alt=""
                />
                <img
                  className={`h-12 w-auto sm:h-16 transition-all duration-500 ${showReveal
                    ? "lg:block lg:opacity-100"
                    : "lg:hidden lg:opacity-0"
                    }`}
                  src={logo}
                  alt=""
                />
              </Link>
              <div className="-mr-2 flex items-center lg:hidden">
                <Popover.Button className="p-2 inline-flex items-center justify-center text-white border-transparent focus:border-transparent focus:ring-0">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-10 w-10" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden space-x-8 lg:flex lg:ml-auto lg:mr-10">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-base font-medium transition text-white hover:text-gray-300 uppercase"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="hidden lg:flex lg:items-center lg:space-x-6">
          {story.content.cta_text &&
            <Button to={story.content.cta_link} text={story.content.cta_text}/>
          }
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-300 ease-out"
        enterFrom="filter opacity-0"
        enterTo="filter opacity-100"
        leave="duration-300 ease-in"
        leaveFrom="filter opacity-100"
        leaveTo="filter opacity-0"
      >
        <Popover.Panel
          focus
          className="fixed top-0 inset-x-0 transition transform origin-top lg:hidden"
        >
          <div className="bg-black overflow-hidden w-screen h-screen">
            <div className="p-2 inline-flex items-center justify-end text-white  w-full">
              <div className="">
                <Popover.Button className="p-2 inline-flex items-center justify-center text-white hover:text-gray-100 border-transparent focus:border-transparent focus:ring-0 mt-1" ref={buttonRef}>
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-10 w-10" aria-hidden="true"/>
                </Popover.Button>
              </div>
            </div>
            <div className="flex justify-center items-center">
              
              <div className="px-2 space-y-1">
                <div className="pb-8 pt-8">
                  <img src={logoBanner} className="w-42 mx-auto"></img>
                </div>
                
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => handleClickPanel()} 
                    className="block px-3 py-2 text-3xl font-medium text-white text-center uppercase"
                  >
                    {item.name}
                  </Link>
                ))}

                {story.content.cta_text &&

                <Link
                  to={story.content.cta_link}
                  className="block px-3 py-2 text-3xl font-medium text-white text-center uppercase"
                >
                  {story.content.cta_text}
                </Link>
                }
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default Header;
