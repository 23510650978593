import React from "react";
import PropTypes from "prop-types";
import FluidImage from "~components/fluidImage";

export default function Partner({ blok }) {
  return (
    <div className="col-span-1 flex justify-center py-8 px-8">
      <div className="min-h-12 w-full">
        <FluidImage image={blok.image} blok={blok} />
      </div>
    </div>
  );
}

Partner.propTypes = {
  blok: PropTypes.object,
};
