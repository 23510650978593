import React from "react";
import PropTypes from "prop-types";
import RichText from "~components/richText";

export default function SmallTextSection({ blok }) {
  return (
    <section className="bg-white">
      <div className="max-w-max lg:max-w-7xl mx-auto py-8 px-4">
        <div className="relative">
          <div className="relative md:p-6">
            <div className="lg:grid lg:grid-cols-4 lg:gap-6 text-center lg:text-left">
              <div className="mt-auto mb-auto">
                <p
                  className={`text-4xl leading-8 font-bold tracking-tight text-black uppercase sm:text-6xl pt-6 lg:pt-0`}
                >
                  {blok.headline}
                </p>
              </div>
              <div className="col-span-3">
                <div>
                  <div
                    className={`font-regular lg:font-regular text-xl lg:text-2xl py-6 text-black leading-10	`}
                  >
                    <RichText document={blok.copy} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

SmallTextSection.propTypes = {
  blok: PropTypes.object,
};
