import React from "react";
import PropTypes from "prop-types";
import DynamicComponent from "~components/dynamicComponent";

export default function Partners({ blok }) {
  return (
    <section>
      <div className="bg-black py-4 lg:py-16">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-4xl font-bold text-white sm:text-5xl sm:tracking-tight lg:text-6xl uppercase pb-0 lg:pb-12 text-center">
            {blok.headline}{" "}
          </h2>

          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
            {blok.block.map((item) => {
              return <DynamicComponent blok={item} key={item._uid} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

Partners.propTypes = {
  blok: PropTypes.object,
};
