import React from "react";

import SEO from "~components/seo";
import Layout from "~components/layout";

import DynamicComponent from "~components/dynamicComponent";
import { ParallaxProvider } from 'react-scroll-parallax';

import useStoryblok from "../lib/storyblok";
import SbEditable from "storyblok-react";
import PropTypes from "prop-types";

function Page({ pageContext, location }) {
  let story = pageContext.story;
  story = useStoryblok(story, location);

  const components = story.content.blocks.map((blok) => {
    return <DynamicComponent blok={blok} key={blok._uid} />;
  });

  return (
    <Layout>
      <SEO
        keywords={story.content.keywords}
        title={story.content.seo_title}
        description={story.content.seo_description}
      />
                  <ParallaxProvider>

      <SbEditable content={story.content}>{components}</SbEditable>
      </ParallaxProvider>
    </Layout>
  );
}

export default Page;

Page.propTypes = {
  pageContext: PropTypes.node.isRequired,
  location: PropTypes.node.isRequired,
};
