import React from "react";
import Button from "~components/button";
import PropTypes from "prop-types";
import RichText from "~components/richText";
import DynamicComponent from "~components/dynamicComponent";

export default function Services({ blok }) {
  return (
    <section className="bg-black">
      <div className="max-w-7xl mx-auto pt-16 pb-40 lg:pb-64 px-4 sm:px-6 lg:px-8 ">
        <div className="text-center">
          <h2 className="text-base font-regular text-theme-grey tracking-wide text-3xl lg:text-4xl">
            {blok.subline}
          </h2>
          <h3 className="mt-5 text-4xl font-bold text-white sm:text-5xl sm:tracking-tight lg:text-6xl leading-10">
            {blok.headline}
          </h3>
          <div className="max-w-xl mt-5 mx-auto text-xl text-white leading-10">
            <RichText document={blok.copy} />
          </div>

          <div className="flex flex-wrap py-8 justify-center">
            {blok.block.map((item) => {
              return <DynamicComponent blok={item} key={item._uid} />;
            })}
          </div>
          <div className="pb-6">
            {blok.cta_text && (
              <Button text={blok.cta_text} to={blok.cta_link} arrow />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

Services.propTypes = {
  blok: PropTypes.node.isRequired,
};
