import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";

export default function ListSelect({ blok }) {
  var people = [];

  if (blok.options != "" || blok.options != null) {
    var split_options = blok.options.split(",");
    for (var option in split_options) {
      people.push({ name: split_options[option] });
    }
  } else {
    people = [
      { name: "Project Size" },
    ];
  }

  const [selected, setSelected] = useState(people[0]);

  return (
    <div className="col-span-6 sm:col-span-3">
      <input type="text" name="select" value={selected.name} className='hidden'/>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="mt-1 w-full shadow-sm sm:text-xl font-regular h-16 bg-white text-left pl-3">
            <span className="block truncate text-gray-600">
              {selected.name}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `${active ? "text-black bg-gray-200" : "text-gray-900"}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? "text-amber-600" : "text-amber-600"
                          }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

ListSelect.propTypes = {
  blok: PropTypes.object,
};
