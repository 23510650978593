import React from "react";
import DynamicComponent from "~components/dynamicComponent";
import PropTypes from "prop-types";
import RichText from "~components/richText";
import { Link } from "gatsby";
import { ArrowRightIcon } from "@heroicons/react/outline";

export default function ImageWithText({ blok }) {
  return (
    <section>
      <div className="pb-2 lg:py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="relative">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 text-center lg:text-left">
              <div
                className={`mb-16 lg:mb-0 lg:pr-16 text-gray-500 lg:-mt-16 ${
                  blok.reverse ? "lg:order-0" : "lg:order-2"
                }`}
              >
                {blok.block.map((item) => {
                  return <DynamicComponent blok={item} key={item._uid} />;
                })}
              </div>

              <div
                className={`prose prose-indigo prose-lg lg:max-w-none lg:pl-8`}
              >
                <h2 className="leading-6 text-indigo-600 font-regular tracking-wide text-3xl text-theme-grey">
                  {blok.subline}
                </h2>
                <h3 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl leading-10	">
                  {blok.headline}
                </h3>

                <div className="font-regular text-xl py-4 text-theme-grey leading-10">
                  <RichText document={blok.copy} />
                </div>
                <div>
                  {blok.cta_text && (
                    <Link
                      to={blok.cta_link}
                      className="group inline-flex items-center justify-center border border-transparent text-base font-medium text-2xl text-black"
                    >
                      {blok.cta_text}
                      <ArrowRightIcon
                        className="ml-2 -mr-0.5 h-5 w-5 transition-transform transform group-hover:translate-x-2"
                        aria-hidden="true"
                      />
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-8 inline-flex"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

ImageWithText.propTypes = {
  image: PropTypes.node.isRequired,
  blok: PropTypes.node.isRequired,
};
