import React from "react";

import SbEditable from "storyblok-react";
import Img from "gatsby-image";
import { getFixedGatsbyImage } from "gatsby-storyblok-image";
import PropTypes from "prop-types";

const FixedImage = ({ image, blok, width = 900, blur=true }) => {
  const fixedProps = getFixedGatsbyImage(image.filename, {
    width: width,
    quality: "50",
  });

  return (
    <SbEditable content={blok}>
      <Img fixed={fixedProps} placeholderClassName={`${blur ? "placeholder-img": ""}`} fadeIn={blur} draggable={false} alt={image.alt}/>
    </SbEditable>
  );
};

export default FixedImage;

FixedImage.propTypes = {
  image: PropTypes.node.isRequired,
  blok: PropTypes.node.isRequired,
  width: PropTypes.number,
  blur: PropTypes.bool,
};
