import React from "react";
import PropTypes from "prop-types";
import DynamicComponent from "~components/dynamicComponent";

export default function ContactForm({ blok }) {
  return (
    <section className="bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 lg:py-24">
        <form
          method="POST"
          action={blok.redirect}
          data-netlify="true"
          name={blok.form_name}
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value={blok.form_name} />
          <div className="grid grid-cols-6 gap-6">
            {blok.block.map((item) => {
              return <DynamicComponent blok={item} key={item._uid} />;
            })}
          </div>

          <div className="py-3 text-left">
            <button
              type="submit"
              className="bg-transparent transition-colors	 border border-2 border-white py-2 px-8 inline-flex justify-center text-lg font-regular text-white focus:outline-none hover:bg-theme-grey"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

ContactForm.propTypes = {
  blok: PropTypes.object,
};
