import React from "react";
import PropTypes from "prop-types";
import DynamicComponent from "~components/dynamicComponent";

export default function DetailsSection({ blok }) {
  return (
    <section className="bg-black">
      <div className="max-w-max lg:max-w-7xl mx-auto py-8">
        <h2 className="mb-5 text-4xl font-regular text-white sm:text-xl sm:tracking-tight lg:text-5xl text-center">
          {blok.headline}
        </h2>
        <div className="max-w-max lg:max-w-5xl mx-auto">
          {blok.block.map((item) => {
            return <DynamicComponent blok={item} key={item._uid} />;
          })}
        </div>
      </div>
    </section>
  );
}

DetailsSection.propTypes = {
  blok: PropTypes.node.isRequired,
};
