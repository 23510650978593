import React from "react";
import PropTypes from "prop-types";
import FluidImage from "~components/fluidImage";
import FixedImage from "~components/fixedImage";

export default function hero({ blok }) {
  return (
    <section
      className="relative h-screen overflow-hidden"
      style={{ maxHeight: "870px" }}
    >
      <div className="absolute inset-x-0 bottom-0" />
      <div>
        <div className="sm:overflow-hidden">
          <div className="absolute inset-0 w-full h-full hero">
            <FixedImage blok={blok} image={blok.image} width={1920} blur={false}/>
            <div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-gray-600 mix-blend-multiply opacity-50	" />
          </div>
        </div>
      </div>

      <div className="absolute inset-0 flex w-screen-full h-screen-full items-center justify-center">
        <div className="w-3/5 lg:w-96">
          <FluidImage blok={blok} image={blok.logo} blur={false}/>
        </div>
      </div>
    </section>
  );
}

hero.propTypes = {
  blok: PropTypes.node.isRequired,
};
